import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const messages = {
    gl: {
        errorConexionApi: 'Produciuse un erro. Por favor, tenteo de novo máis tarde.',
        portada: {
            buscador: 'Consulta a túa cobertura',
            bannerSuperior: 'Contrata a túa fibra óptica, a conexión máis rápida.',
            listaCaracteristicas: {
                parrafo1: 'Prezo final (IVE Incluido)',
                parrafo2: 'Máxima velocidade de rede (Ata 1Gb!)',
                parrafo3: 'Sen sorpresas',
                parrafo4: '   e... router e instalación gratuítos!'
            },
            botonQueroa: 'Quéroa!',
            textoEngadirFibra: {
                parte1: '...ou engade',
                parte2: 'de velocidade por tan só',
                parte3: 'Esta é a miña!'
            },
            textoOpcionFibra: {
                parte1: 'A túa opción de Fibra',
                parte2: 'Galega'
            },
            caracteristicas: {
                parrafo1: 'Transparentes',
                parrafo2: 'Sen letras pequenas, paga o xusto e necesario.',
                parrafo3: 'SEN permanencia',
                parrafo4: 'Síntete libre para irte cando queiras, sen compromiso.',
                parrafo5: 'Flexibles',
                parrafo6: 'Poderás configurar a túa tarifa sempre que queiras, sen coste (Só Fibra, Fibra e Teléfono fixo, Fibra e só un móbil).',
                parrafo7: 'Rápido e sinxelo',
                parrafo8: 'Atención personalizada e directa, fala con nosoutros directamente, sen teleoperadores nin outros intermediarios.'
            },
            botonInteresame: 'Interésame!',
            textoPulpo: {
                parte1: 'As nosas mellores tarifas de fibra e móbil',
                parte2: 'Escolle a que mellor se adapte a ti'
            },
            precios: {
                nombreTarifa: 'Tarifa móbil',
                llamadasIlimitadas: 'Chamadas ilimitadas'
            },
            textoChamamoste: {
                parte1: 'Chamámoste',
                parte2: 'se o precisas'
            },
            textoEngadirFixo: {
                parte1: 'Engade',
                parte2: 'teléfono fixo'
            },
            bannerChamadas: {
                chamadas: 'Chamadas',
                deFixoAFixo: 'de fixo a fixo',
                deFixoAMobil: 'chamadas de fixo a móbil'
            },
            footer: {
                llamanos: 'Chámanos ao ',
                textoBannerCookies: 'Empregamos cookies propias para mellorar a tua accesibilidade, así como para personalizar e analizar a tua navegación.',
                textoBannerCookiesInfo: 'Máis información',
                marca: 'unha marca de',
                o_en: 'ou en'

            },
            politicaPrivacidad: {
                parte1: 'Lín e acepto a',
                parte2: 'política de privacidade.',
                parte3: 'Política de Privacidade',
                success: 'Enviado correctamente. Poñeremonos en contacto con vostede o antes posible.',
                error: 'Produciuse un erro. Por favor, tenteo de novo máis tarde.'
            }
        },
        configuradorCarrito: {
            titulo: 'Contas claras sen un depende',
            total: 'TOTAL (ive incluido)',
            parrafo2: 'Sen subidas de precio',
            parrafo4: 'Sen permanencia!'
        },
        configuradorFibra: {
            lineaFixa: 'Liña fixa',
            llamadasIlimitadas: 'Chamadas ilimitadas a fixos nacionais +100 min a móbil.'

        },
        configuradorMovil: {
            lineaMovil: 'Liñas móbiles',
            llamadasIlimitadas: 'Todas elas con chamadas ilimitadas'

        },
        configuradorPedido: {
            configurarPaquete: 'Configura o teu paquete',
            datosPersonales: 'Os teus datos persoais'
        },
        orderForm: {
            nombre: 'Nome',
            apellidos: 'Apelidos',
            movil: 'Teléfono móbil',
            fechaNacimiento: 'Data de nacemento',
            calle: 'Rúa',
            puerta: 'Porta',
            escalera: 'Escaleira',
            direccionFacturacion: 'Mesma dirección de facturación',
            textoAceptarCondiciones: {
                texto: 'Acepto as ',
                textoEnlace: 'condicións'
            },
            poblacion: 'Poboación',
            numeroCuenta: 'Número de conta para a domiciliación',
            botonGuardar: 'Gardar',
            numeroConta: 'Os datos bancarios serán solicitados polos nosos operadores pola súa seguridade.',
            errores: {
                campoObligatorio: 'Campo obrigatorio.',
                email: 'Introduza unha dirección de correo electrónico.',
                movil: 'Introduza un número de móbil.',
                dni: 'Introduza o número do DNI.',
                cif: 'Introduza o número do CIF.',
                coberturaCP: 'Non dispoñemos de cobertura de fibra nese código postal.',
                coberturaDireccion: 'Non se encontrou cobertura de fibra. Envíanos un correo electrónico a comercial@fibra.gal para revisalo.',
                codigoPostal: 'Introduza un código postal de 5 díxitos.',
                cobertura: 'Dispón de cobertura de fibra!',
                menorEdad: 'Para facer un pedido é necesario ser maior de idade.'
            }
        },
        lineaMovil: {
            lineasAdicionales: 'Liñas adicionais',
            portabilidad: 'Portabilidade',
            numeroParaPortar: 'Número móbil a portar',
            hint: 'Só é posible portar liñas de postpago'

        },
        lineaFija: {
            numeroParaPortar: 'Número fixo a portar'
        },
        avisoLegal: {
            titulo1: 'OBXECTO E ACEPTACIÓN',
            parrafo1: 'O presente aviso legal regula o uso do sitio web www.fibra.gal (en diante, A WEB), do que é titular NORVOZ TELECOM, S.L. (en diante, O PROPIETARIO DA WEB). A navegación polo sitio web do PROPIETARIO DA WEB atribúe a condición de usuario do mesmo e implica a aceptación plena e sen reservas de todas e cada unha das disposicións incluídas neste Aviso Legal, que poden sufrir modificacións. O usuario obrígase a facer un uso correcto do sitio web de conformidade coas leis, a boa fe, a orde pública, os usos do tráfico e o presente Aviso Legal. O usuario responderá fronte ao PROPIETARIO DA WEB ou fronte a terceiros, de calquera danos e prexuízos que puidesen causarse como consecuencia do incumprimento da devandita obrigación.',
            titulo2: 'IDENTIFICACIÓN E COMUNICACIÓNS',
            parrafo2: 'O PROPIETARIO DA WEB, en cumprimento da Lei 34/2002, do 11 de xullo, de servizos da sociedade da información e de comercio electrónico, infórmalle de que:',
            parrafo3: {
                texto: 'A súa denominación social é: ',
                textoNegrita: 'NORVOZ TELECOM, S.L.'
            },
            parrafo4: {
                texto: 'O seu CIF é:',
                textoNegrita: 'B27417963'
            },
            parrafo5: {
                texto: 'O seu domicilio social está en: ',
                textoNegrita: 'C/ LODEIRO 4 ENTR. B – 27850 VIVEIRO – LUGO'
            },
            parrafo6: 'Inscrita no Rexistro Mercantil de Lugo, Tomo 428, Folio 157, Folla LU-14038, Inscripcion Primeira.',
            parrafo7: 'Para comunicarse connosco, poñemos á súa disposición diferentes medios de contacto que detallamos a continuación:',
            parrafo8: {
                texto: 'Tfno:',
                telefono: '982650065'
            },
            parrafo9: {
                texto: 'Email:',
                correo: 'info@norvoz.es'
            },
            parrafo10: 'Todas as notificacións e comunicacións entre os usuarios e O PROPIETARIO DA WEB consideraranse eficaces, para todos os efectos, cando se realicen a través de correo postal ou calquera outro medio dos detallados anteriormente.',
            titulo3: 'CONDICIÓNS DE ACCESO E UTILIZACIÓN',
            parrafo11: 'O sitio web e os seus servizos son de acceso libre e gratuíto, con todo, O PROPIETARIO DA WEB condiciona a utilización dalgúns dos servizos ofrecidos na súa web ao previo enchemento do correspondente formulario. O usuario garante a autenticidade e actualidade de todos aqueles datos que comunique ao PROPIETARIO DA WEB e será o único responsable das manifestacións falsas ou inexactas que realice. O usuario comprométese expresamente a facer un uso adecuado dos contidos e servizos do PROPIETARIO DA WEB e a non empregalos para, entre outros:',
            parrafo12: 'a) Difundir contidos, delituosos, violentos, pornográficos, racistas, xenófobo,ofensivos, de apoloxía do terrorismo ou, en xeral, contrarios á lei ou á orde pública.',
            parrafo13: 'b) Introducir na rede virus informáticos ou realizar actuacións susceptibles de alterar, estragar, interromper ou xerar erros ou danos nos documentos electrónicos, datos ou sistemas físicos e lóxicos do PROPIETARIO DA WEB ou de terceiras persoas; así como obstaculizar o acceso doutros usuarios ao sitio web e aos seus servizos mediante o consumo masivo dos recursos informáticos a través dos cales O PROPIETARIO DA WEB presta os seus servizos.',
            parrafo14: 'c) Tentar acceder ás contas de correo electrónico doutros usuarios ou a áreas restrinxidas dos sistemas informáticos do PROPIETARIO DA WEB ou de terceiros e, no seu caso, extraer información.',
            parrafo15: 'd) Vulnerar os dereitos de propiedade intelectual ou industrial, así como violar a confidencialidade da información do PROPIETARIO DA WEB ou de terceiros.',
            parrafo16: 'e) Suplantar a identidade doutro usuario, das administracións públicas ou dun terceiro.',
            parrafo17: 'f) Reproducir, copiar, distribuír, poñer a disposición ou de calquera outra forma comunicar publicamente, transformar ou modificar os contidos, a menos que se conte coa autorización do titular dos correspondentes dereitos ou iso resulte legalmente permitido.',
            parrafo18: 'g) Solicitar datos con finalidade publicitaria e de remitir publicidade de calquera clase e comunicacións con fins de venda ou outras de natureza comercial sen que medie a súa previa solicitude ou consentimento.',
            parrafo19: 'Todos os contidos do sitio web, como textos, fotografías, gráficos, imaxes, iconas, tecnoloxía, software, así como o seu deseño gráfico e códigos fonte, constitúen unha obra cuxa propiedade pertence ao PROPIETARIO DA WEB, sen que poidan entenderse cedidos ao usuario ningún dos dereitos de explotación sobre os mesmos máis aló do estritamente necesario para o correcto uso da web.',
            parrafo20: 'En definitiva, os usuarios que accedan a este sitio web poden visualizar o contidos e efectuar, no seu caso, copias privadas autorizadas sempre que os elementos reproducidos non sexan cedidos posteriormente a terceiros, nin se instalen a servidores conectados a redes, nin sexan obxecto de ningún tipo de explotación. Así mesmo, todas as marcas, nomes comerciais ou signos distintivos de calquera clase que aparecen no sitio web son propiedade do PROPIETARIO DA WEB, sen que poida entenderse que o uso ou acceso ao mesmo atribúa ao usuario dereito algún sobre os mesmos.',
            parrafo21: 'A distribución, modificación, cesión ou comunicación pública dos contidos e calquera outro acto que non fose expresamente autorizado polo titular dos dereitos de explotación quedan prohibidos.',
            parrafo22: 'O establecemento dun hiperenlace non implica en ningún caso a existencia de relacións entre O PROPIETARIO DA WEB e o propietario do sitio web na que se estableza, nin a aceptación e aprobación por parte do PROPIETARIO DA WEB dos seus contidos ou servizos. Aquelas persoas que se propoñan establecer un hiperenlace previamente deberán solicitar autorización por escrito ao PROPIETARIO DA WEB. En todo caso, o hiperenlace unicamente permitirá o acceso á home-page ou páxina de inicio do noso sitio web, así mesmo deberá absterse de realizar manifestacións ou indicacións falsas, inexactas ou incorrectas sobre O PROPIETARIO DA WEB, ou incluír contidos ilícitos, contrarios aos bos costumes e á orde pública.',
            parrafo23: 'O PROPIETARIO DA WEB non se responsabiliza do uso que cada usuario lle dea aos materiais postos a disposición neste sitio web nin das actuacións que realice en base aos mesmos.',
            titulo4: 'EXCLUSIÓN DE GARANTÍAS E DE RESPONSABILIDADE',
            parrafo24: 'O contido do presente sitio web é de carácter xeral e ten unha finalidade meramente informativa, sen que se garanta plenamente o acceso a todos os contidos, nin a súa minuciosidade, corrección, vixencia ou actualidade, nin a súa idoneidade ou utilidade para un obxectivo específico.',
            parrafo25: 'O PROPIETARIO DA WEB exclúe, ata onde permite o ordenamento xurídico, calquera responsabilidade polos danos e prexuízos de toda natureza derivados de:',
            parrafo26: 'a) A imposibilidade de acceso ao sitio web ou a falta de veracidade, exactitude, minuciosidade e/ou actualidade dos contidos, así como a existencia de vicios e defectos de toda clase dos contidos transmitidos, difundidos, almacenados, postos a disposición aos que se accedeu a través do sitio web ou dos servizos que se ofrecen.',
            parrafo27: 'b) A presenza de virus ou doutros elementos nos contidos que poidan producir alteracións nos sistemas informáticos, documentos electrónicos ou datos dos usuarios.',
            parrafo28: 'c) O incumprimento das leis, a boa fe, a orde pública, os usos do tráfico e o presente aviso legal como consecuencia do uso incorrecto do sitio web. En particular, e a modo exemplificativo, O PROPIETARIO DA WEB non se fai responsable das actuacións de terceiros que vulneren dereitos de propiedade intelectual e industrial, segredos empresariais, dereitos á honra, á intimidade persoal e familiar e á propia imaxe, así como a normativa en materia de competencia desleal e publicidade ilícita.',
            parrafo29: 'Así mesmo, O PROPIETARIO DA WEB declina calquera responsabilidade respecto da información que se ache fose desta web e non sexa xestionada directamente polo noso webmaster. A función dos links que aparecen nesta web é exclusivamente a de informar o usuario sobre a existencia doutras fontes susceptibles de ampliar os contidos que ofrece este sitio web. O PROPIETARIO DA WEB non garante nin se responsabiliza do funcionamento ou accesibilidade dos sitios enlazados; nin suxire, convida ou recomenda a visita aos mesmos, polo que tampouco será responsable do resultado obtido. O PROPIETARIO DA WEB non se responsabiliza do establecemento de hipervínculos por parte de terceiros.',
            titulo5: 'POLÍTICA DE PRIVACIDADE',
            parrafo30: 'Cando precisemos obter información pola súa banda, sempre lle solicitaremos que nola proporcione voluntariamente de forma expresa. Os datos solicitados a través dos formularios de recollida de datos do sitio web ou outras vías serán incorporados a un ficheiro de datos de carácter persoal debidamente inscrito no Rexistro Xeral de Protección de Datos da Axencia Española de Protección de Datos, do cal é responsable O PROPIETARIO DA WEB. Esta entidade tratará os datos de forma confidencial e exclusivamente coa finalidade de ofrecer os servizos solicitados, con todas as garantías legais e de seguridade que impón a Lei Orgánica 3/2018, do 5 de decembro, de Protección de Datos Persoales, e a Lei 34/2002, do 11 de xullo, de Servizos da Sociedade da Información e Comercio Electrónico.',
            parrafo31: 'O PROPIETARIO DA WEB comprométese a non ceder, vender, nin compartir o datos con terceiros sen a súa aprobación expresa. Así mesmo,  NORVOZ TELECOM, S.L. cancelará ou rectificará os datos cando resulten inexactos, incompletos ou deixasen de ser necesarios ou pertinentes para a súa finalidade, de conformidade co previsto na Lei Orgánica 3/2018, do 5 de decembro, de Protección de Datos Persoales e garantía dos dereitos dixitáis. O usuario poderá revogar o consentimento prestado e exercer os dereitos de acceso, rectificación, cancelación e oposición dirixíndose para ese efecto ao domicilio social de  NORVOZ TELECOM, S.L., sito en  C/ LODEIRO 4  ENTR.  B – 27850 VIVEIRO - LUGO, identificándose debidamente e indicando de forma visible o concreto dereito que se exerce.',
            parrafo32: 'O PROPIETARIO DA WEB adopta os niveis de seguridade correspondentes requiridos pola citada Lei Orgánica 3/2018 e demais normativa aplicable. Con todo, non asume ningunha responsabilidade polos danos e prexuízos derivados de alteracións que terceiros poden causar nos sistemas informáticos, documentos electrónicos ou ficheiros do usuario.',
            parrafo33: 'O PROPIETARIO DA WEB poderá utilizar cookies durante a prestación de servizos do sitio web. As cookies son ficheiros físicos de información persoal aloxados no propio terminal do usuario. O usuario ten a posibilidade de configurar o seu programa navegador de maneira que se impida a creación de arquivos cookie ou se advirta da mesma. Se opta a abandonar o noso sitio web a través de ligazóns a sitios web non pertencentes á nosa entidade, O PROPIETARIO DA WEB non se fará responsable das políticas de privacidade dos devanditos sitios web nin das cookies que estes poidan almacenar no computador do usuario.',
            parrafo34: 'A nosa política con respecto ao correo electrónico céntrase en remitir unicamente comunicacións que vostede solicitase recibir. Se prefire non recibir estas mensaxes por correo electrónico ofrecerémoslle a través dos mesmos a posibilidade de exercer o seu dereito de cancelación e renuncia á recepción destas mensaxes, en conformidade co disposto no Título III, artigo 22 da Lei 34/2002 de Servizos para a Sociedade da Información e de Comercio Electrónico.',
            titulo6: 'PROCEDEMENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO',
            parrafo35: 'No caso de que calquera usuario ou un terceiro considere que existen feitos ou circunstancias que revelen o carácter ilícito da utilización de calquera contido e/ou da realización de calquera actividade nas páxinas web incluídas ou accesibles a través do sitio web, deberá enviar unha notificación ao PROPIETARIO DA WEB identificándose debidamente, especificando as supostas infraccións e declarando expresamente e baixo a súa responsabilidade que a información proporcionada na notificación é exacta.',
            parrafo36: 'Para toda cuestión litixiosa que incumba ao sitio web do PROPIETARIO DA WEB, será de aplicación a lexislación española, sendo competentes os Xulgados e Tribunais de LUGO (España).',
            titulo7: 'PUBLICACIÓNS',
            parrafo37: 'A información administrativa facilitada a través do sitio web non substitúe a publicidade legal das leis, normativas, plans, disposicións xerais e actos que teñan que ser publicados formalmente aos diarios oficiais das administracións públicas, que constitúen o único instrumento que dá fe da súa autenticidade e contido. A información dispoñible neste sitio web debe entenderse como unha guía sen propósito de validez legal.'

        },
        politicaDeCookies: {
            parrafo1: 'O noso sitio web utiliza cookies para mellorar a navegación e obter datos estatísticos sobre as visitas obtidas.',
            parrafo2: 'As cookies son pequenos arquivos de texto que se instalan no equipo desde o cal se visita o noso sitio web e que nos proporcionan información de forma automática. As cookies en ningún caso pódense considerar como un virus informáticos. Non danan o computador nin retardan o seu funcionamento de ningún xeito. Vostede pode eliminar as nosas Cookies en calquera momento, ou rexeitalas configurando a súa navegador web.',
            parrafo3: 'As cookies asócianse unicamente co navegador dun computador determinado (un usuario anónimo), e non proporcionan por si o nome e apelidos do usuario. Grazas ás cookies, resulta posible que Norvoz recoñeza os navegadores dos usuarios rexistrados despois de que estes rexistráronse por primeira vez, sen que teñan que rexistrarse en cada visita para acceder ás áreas e servizos reservados exclusivamente a eles. Os nosos "cookies" serven para identificar unha sesión de usuarios ("cookies de sesión") e / ou un computador ("cookies temporais"), e non proporcionan por si mesmos nin o nome do usuario nin ningún outro dato persoal. As cookies utilizadas non poden ler os arquivos cookie creados por outros provedores. O usuario ten a posibilidade de configurar o seu navegador para ser avisado en pantalla da recepción de cookies e para impedir a instalación de cookies no seu disco duro. Por favor, consulte as instrucións e manuais do seu navegador para ampliar esta información.',
            parrafo4: 'Norvoz utiliza dous tipos de cookies diferentes no noso sitio web:',
            parrafo5: {
                negrita: 'Cookies de terceiras partes: ',
                texto: 'Cookies de terceiras partes:Cookies analiticas web instaladas no seu computador polo servizo Google Analytics. Son cookies estatísticas, con cóelas podemos coñecer datos útiles para mellorar nosa sitio web. Algúns datos recolleitos son por exemplo: o número de visitas recibidas, a orixe das visitas, as palabras crave utilizadas para encontranos ou as horas de maior afluencia de visitantes. Pode obter máis información sobre a política de privacidade de Google Analytics, visitando a seguinte ligazón:'
            },
            parrafo6: 'Poderá configurar o seu navegador para non recibir estas cookie e serán rexeitadas,. En caso contrario, entendemos que contamos co seu consentimento para a súa instalación.',
            parrafo7: 'Cookies propias, xeradas polo noso sitio web, para diferentes funcións:',
            parrafo8: {
                negrita: 'Cookies de autenticación: ',
                texto: 'Utilizadas para recoñecer aos usuarios rexistrados que se han ?logado? no noso sitio web. Con estas cookies, vostede poderá acceder ás seccións de acceso restrinxido da web. En caso de rexeitar estas Cookies ou ser borradas, as claves de acceso ao noso sitio web, non funcionarán de forma correcta.'
            },
            parrafo9: {
                negrita: 'Cookies de personalización de interface: ',
                texto: 'Usadas para facilitar a navegación do noso sitio web. Permiten lembrar algúns aspectos gráficos da nosa web automaticamente. En caso de rexeitar estas Cookies ou ser borradas, a navegación no noso sitio web, non funcionarán de forma tan sinxela.'
            },
            parrafo10: {
                negrita: 'Cookies publicitarias: ',
                texto: 'Utilizadas para personalizar a publicidade que o noso sitio web proporciónalle en base aos contidos da web e a frecuencia á que se mostren os anuncios.'
            },
            parrafo11: {
                negrita: 'Cookies de publicidade comportamental: ',
                texto: 'Estas cookies almacenan a información dos nosos usuarios e o seu comportamento para despois ofrecer nos espazos publicitarios dispoñibles información relevante para eles. Para permitir, coñecer, bloquear ou eliminar as cookies instaladas no teu equipo podes facelo mediante a configuración das opcións do navegador instalado no seu computador.'
            },
            parrafo12: 'Por exemplo podes atopar información sobre como facelo como facelo no caso que uses como navegador:',
            parrafo13: {
                texto: 'Firefox dende',
                enlace: 'http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we'
            },
            parrafo14: {
                texto: 'Chrome dende',
                enlace: 'http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647'
            },
            parrafo15: {
                texto: 'Explorer dende',
                enlace: 'http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9'

            },
            parrafo16: {
                texto: 'Safari dende',
                enlace: 'http://support.apple.com/kb/ph5042'
            },
            parrafo17: {
                texto: 'Se queres máis información sobre que uso facemos das cookies, envíanos un e-mail a:',
                email: 'info@norvoz.es'

            }
        },
        informacionDatos: {
            parrafo1: {
                textoNegrita: 'Responsable:',
                texto: 'NORVOZ TELECOM, S.L.'
            },
            parrafo2: {
                textoNegrita: 'Domicilio social:',
                texto: 'C/ LODEIRO 4 ENTR. B – 27850 VIVEIRO – LUGO'
            },
            parrafo3: {
                textoNegrita: 'Tfno.:'
            },
            parrafo4: {
                textoNegrita: 'E-mail:'
            },
            parrafo5: {
                textoNegrita: 'Finalidade:',
                texto: 'Alta de servizos con Norvoz Telecom, S.L.'
            },
            parrafo6: {
                textoNegrita: 'Lexitimación:',
                texto: 'Consentimento do interesado.'
            },
            parrafo7: {
                textoNegrita: 'Destinatarios:',
                texto: 'Os datos de carácter persoal que nos facilite mediante este formulario coa finalidade indicada anteriormente, poderán ser cedidos a terceiros, unicamente cando sexa necesario para a xestión do alta do servizo, ademais doutras cesións previstas na lei. Pode exercitar os seus dereitos segundo o incluído a continuación.'
            },
            parrafo8: {
                textoNegrita: 'Dereitos:',
                texto: 'Vd. ten dereito a acceder, rectificar e suprimir os datos, así como a solicitar que se limite o tratamento, opoñerse ao mesmo, ou solicitar a  portabilidade dos seus datos, como se explica na información adicional.'
            },
            parrafo9: {
                textoNegrita: 'Duración:',
                texto: 'Os datos de carácter persoal proporcionados conservaranse por un prazo razoable de tempo ou ata o exercicio do dereito de cancelación. Finalizado o prazo sinalado os datos serán eliminados ou bloqueados, salvo obrigación legal en contrario.'
            },
            parrafo10: {
                textoNegrita: 'Información adicional:',
                textoParte1: 'Pode consultar a información adicional e detallada sobre Protección de Datos no apartado 4 do',
                textoParte2: 'incluído na nosa páxina web.'

            }
        },
        pedidoRealizado: {
            titulo: 'GRAZAS!',
            parrafo1: 'Grazas por confiar en fibra.gal. Como queremos que xa comeces a disfrutar da maior velocidade...xa nos puxemos en marcha!',
            parrafo2: 'Non queda nada para comezar a navegar á maior velocidade, os seguintes pasos son:',
            parrafo3: {
                parte1: 'Recibirás no teu móbil',
                parte2: 'as mensaxes de texto coa documentación preparada e lista para asinar e remitirnos. Unha vez recibamos devanditos documentos, en menos de 7 días terás a túa fibra instalada e...a navegar!'
            },
            parrafo4: 'Moitas grazas de novo e... benvid@!'
        }
    },
    es: {
        errorConexionApi: 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.',
        portada: {
            buscador: 'Consulta tu cobertura',
            bannerSuperior: 'Contrata tu fibra óptica, la conexión más rápida.',
            listaCaracteristicas: {
                parrafo1: 'Precio final (IVA Incluido)',
                parrafo2: 'Máxima velocidad de red (¡Hasta 1Gb!)',
                parrafo3: 'Sin sorpresas',
                parrafo4: ' y... ¡router e instalación gratuítos!'
            },
            botonQueroa: '¡La quiero!',
            textoEngadirFibra: {
                parte1: '...o añade',
                parte2: 'de velocidad por tan solo',
                parte3: '¡Esta es la mía!'
            },
            textoOpcionFibra: {
                parte1: 'Tu opción de Fibra',
                parte2: 'Gallega'
            },
            caracteristicas: {
                parrafo1: 'Transparentes',
                parrafo2: 'Sin letras pequeñas, paga lo justo y necesario.',
                parrafo3: 'SIN permanencia',
                parrafo4: 'Siéntete libre para irte cuando quieras, sin compromiso.',
                parrafo5: 'Flexibles',
                parrafo6: 'Podrás configurar tu tarifa siempre que quieras, sin coste (Solo Fibra, Fibra y Teléfono fijo, Fibra y solo un móvil).',
                parrafo7: 'Rápido y sencillo',
                parrafo8: 'Atención personalizada y directa, habla con nosotros directamente, sin teleoperadores ni otros intermediarios.'
            },
            botonInteresame: '¡Me interesa!',
            textoPulpo: {
                parte1: 'Nuestras mejores tarifas de fibra y móvil',
                parte2: 'Escoge la que mejor se adapte a ti'
            },
            precios: {
                nombreTarifa: 'Tarifa móvil',
                llamadasIlimitadas: 'Llamadas ilimitadas'
            },
            textoChamamoste: {
                parte1: 'Te llamamos',
                parte2: 'si lo necesitas'
            },
            textoEngadirFixo: {
                parte1: 'Añade',
                parte2: 'teléfono fijo'
            },
            bannerChamadas: {
                chamadas: 'Llamadas',
                deFixoAFixo: 'de fijo a fijo',
                deFixoAMobil: 'Llamadas de fijo a móvil'
            },
            footer: {
                llamanos: 'Llámanos al ',
                textoBannerCookies: 'Utilizamos cookies propias para mejorar tu accesibilidad, así como para personalizar y analizar tu navegación.',
                textoBannerCookiesInfo: 'Más información',
                marca: 'una marca de',
                o_en: 'o en'
            },
            politicaPrivacidad: {
                parte1: 'He leído y acepto la',
                parte2: 'política de Privacidad',
                parte3: 'Política de privacidad.',
                success: 'Enviado correctamente. Nos pondremos en contacto con usted lo antes posible.',
                error: 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.'
            }
        },
        configuradorCarrito: {
            titulo: 'Cuentas claras sin un depende',
            total: 'TOTAL (iva incluido)',
            parrafo2: 'Sin subidas de precio',
            parrafo4: '¡Sin permanencia!'
        },
        configuradorFibra: {
            lineaFixa: 'Línea fija',
            llamadasIlimitadas: 'Llamadas ilimitadas a fijos nacionales +100 min a móvil.'
        },
        configuradorMovil: {
            lineaMovil: 'Líneas móviles',
            llamadasIlimitadas: 'Todas ellas con llamadas ilimitadas'

        },
        configuradorPedido: {
            configurarPaquete: 'Configura tu paquete',
            datosPersonales: 'Tus datos personales'
        },
        orderForm: {
            nombre: 'Nombre',
            apellidos: 'Apellidos',
            movil: 'Teléfono móvil',
            fechaNacimiento: 'Fecha de nacimiento',
            calle: 'Calle',
            puerta: 'Puerta',
            escalera: 'Escalera',
            direccionFacturacion: 'Misma dirección de facturación',
            textoAceptarCondiciones: {
                texto: 'Acepto las ',
                textoEnlace: 'condiciones'
            },
            poblacion: 'Población',
            numeroCuenta: 'Número de cuenta para domiciliación',
            botonGuardar: 'Guardar',
            numeroConta: 'Los datos bancarios serán solicitados por nuestros operadores por su seguridad.',
            errores: {
                campoObligatorio: 'Campo obligatorio.',
                email: 'Introduzca una dirección de correo electrónico.',
                movil: 'Introduzca un número de móvil.',
                dni: 'Introduzca el número de su DNI.',
                cif: 'Introduzca el número del CIF.',
                coberturaCP: 'No disponemos de cobertura de fibra en ese código postal.',
                coberturaDireccion: 'No se ha encontrado cobertura de fibra. Envíanos un correo electrónico a comercial@fibra.gal para revisarlo.',
                codigoPostal: 'Introduzca un código postal de 5 dígitos.',
                cobertura: 'Dispone de cobertura de fibra!',
                menorEdad: 'Para realizar un pedido es necesario ser mayor de edad.'
            }
        },
        lineaMovil: {
            lineasAdicionales: 'Lineas adicionales',
            portabilidad: 'Portabilidad',
            numeroParaPortar: 'Número móvil a portar',
            hint: 'Solo es posible portar líneas de postpago'
        },
        lineaFija: {
            numeroParaPortar: 'Número fijo a portar'
        },
        avisoLegal: {
            titulo1: 'OBJETO Y ACEPTACIÓN',
            parrafo1: 'El presente aviso legal regula el uso del sitio web www.fibra.gal (en adelante, LA WEB), del que es titular NORVOZ TELECOM, S.L. (en adelante, EL PROPIETARIO DE LA WEB). La navegación por el sitio web de EL PROPIETARIO DE LA WEB atribuye la condición de usuario del mismo e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal, que pueden sufrir modificaciones. El usuario se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal. El usuario responderá frente a EL PROPIETARIO DE LA WEB o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.',
            titulo2: 'IDENTIFICACIÓN Y COMUNICACIONES',
            parrafo2: 'EL PROPIETARIO DE LA WEB, en cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, le informa de que:',
            parrafo3: {
                texto: 'Su denominación social es: ',
                textoNegrita: 'NORVOZ TELECOM, S.L.'
            },
            parrafo4: {
                texto: 'Su CIF es: ',
                textoNegrita: 'B27417963'
            },
            parrafo5: {
                texto: 'Su domicilio social está en: ',
                textoNegrita: 'C/ LODEIRO 4 ENTR. B – 27850 VIVEIRO – LUGO'
            },
            parrafo6: 'Inscrita en el Registro Mercantil de Registro Mercantil de Lugo, Tomo 428, Folio 157, Hoja LU-14038, Inscripcion Primera.',
            parrafo7: 'Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos a continuación:',
            parrafo8: {
                texto: 'Tfno:',
                telefono: '982650065'
            },
            parrafo9: {
                texto: 'Email:',
                correo: 'info@norvoz.es'
            },
            parrafo10: 'Todas las notificaciones y comunicaciones entre los usuarios y EL PROPIETARIO DE LA WEB se considerarán eficaces, a todos los efectos, cuando se realicen a través de correo postal o cualquier otro medio de los detallados anteriormente.',
            titulo3: 'CONDICIONES DE ACCESO Y UTILIZACIÓN',
            parrafo11: 'El sitio web y sus servicios son de acceso libre y gratuito, no obstante, EL PROPIETARIO DE LA WEB condiciona la utilización de algunos de los servicios ofrecidos en su web a la previa cumplimentación del correspondiente formulario. El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a EL PROPIETARIO DE LA WEB y será el único responsable de las manifestaciones falsas o inexactas que realice. El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios de EL PROPIETARIO DE LA WEB y a no emplearlos para, entre otros:',
            parrafo12: 'a) Difundir contenidos, delictivos, violentos, pornográficos, racistas, xenófobo, ofensivos, de apología del terrorismo o, en general, contrarios a la ley o al orden público.',
            parrafo13: 'b) Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de EL PROPIETARIO DE LA WEB o de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales EL PROPIETARIO DE LA WEB presta sus servicios.',
            parrafo14: 'c) Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de EL PROPIETARIO DE LA WEB o de terceros y, en su caso, extraer información.',
            parrafo15: 'd) Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de EL PROPIETARIO DE LA WEB o de terceros.',
            parrafo16: 'e) Suplantar la identidad de otro usuario, de las administraciones públicas o de un tercero.',
            parrafo17: 'f) Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido.',
            parrafo18: 'g) Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento.',
            parrafo19: 'Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos, tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a EL PROPIETARIO DE LA WEB, sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web.',
            parrafo20: 'En definitiva, los usuarios que accedan a este sitio web pueden visualizar lo contenidos y efectuar, en su caso, copias privadas autorizadas siempre que los elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen a servidores conectados a redes, ni sean objeto de ningún tipo de explotación. Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de EL PROPIETARIO DE LA WEB, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos.',
            parrafo21: 'La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación quedan prohibidos.',
            parrafo22: 'El establecimiento de un hiperenlace no implica en ningún caso la existencia de relaciones entre EL PROPIETARIO DE LA WEB y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación por parte de EL PROPIETARIO DE LA WEB de sus contenidos o servicios. Aquellas personas que se propongan establecer un hiperenlace previamente deberán solicitar autorización por escrito a EL PROPIETARIO DE LA WEB. En todo caso, el hiperenlace únicamente permitirá el acceso a la home-page o página de inicio de nuestro sitio web, asimismo deberá abstenerse de realizar manifestaciones o indicaciones falsas, inexactas o incorrectas sobre EL PROPIETARIO DE LA WEB, o incluir contenidos ilícitos, contrarios a las buenas costumbres y al orden público.',
            parrafo23: 'EL PROPIETARIO DE LA WEB no se responsabiliza del uso que cada usuario le dé a los materiales puestos a disposición en este sitio web ni de las actuaciones que realice en base a los mismos.',
            titulo4: 'EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD',
            parrafo24: 'El contenido del presente sitio web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.',
            parrafo25: 'EL PROPIETARIO DE LA WEB excluye, hasta donde permite el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de:',
            parrafo26: 'a) La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición a los que se haya accedido a través del sitio web o de los servicios que se ofrecen.',
            parrafo27: 'b) La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los usuarios.',
            parrafo28: 'c) El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente aviso legal como consecuencia del uso incorrecto del sitio web. En particular, y a modo ejemplificativo, EL PROPIETARIO DE LA WEB no se hace responsable de las actuaciones de terceros que vulneren derechos de propiedad intelectual e industrial, secretos empresariales, derechos al honor, a la intimidad personal y familiar y a la propia imagen, así como la normativa en materia de competencia desleal y publicidad ilícita.',
            parrafo29: 'Asimismo, EL PROPIETARIO DE LA WEB declina cualquier responsabilidad respecto a la información que se halle fuera de esta web y no sea gestionada directamente por nuestro webmaster. La función de los links que aparecen en esta web es exclusivamente la de informar al usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece este sitio web. EL PROPIETARIO DE LA WEB no garantiza ni se responsabiliza del funcionamiento o accesibilidad de los sitios enlazados; ni sugiere, invita o recomienda la visita a los mismos, por lo que tampoco será responsable del resultado obtenido. EL PROPIETARIO DE LA WEB no se responsabiliza del establecimiento de hipervínculos por parte de terceros.',
            titulo5: 'POLÍTICA DE PRIVACIDAD',
            parrafo30: 'Cuando precisemos obtener información por su parte, siempre le solicitaremos que nos la proporcione voluntariamente de forma expresa. Los datos recabados a través de los formularios de recogida de datos del sitio web u otras vías serán incorporados a un fichero de datos de carácter personal debidamente inscrito en el Registro General de Protección de Datos de la Agencia Española de Protección de Datos, del cual es responsable EL PROPIETARIO DE LA WEB. Esta entidad tratará los datos de forma confidencial y exclusivamente con la finalidad de ofrecer los servicios solicitados, con todas las garantías legales y de seguridad que impone la  Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico.',
            parrafo31: 'EL PROPIETARIO DE LA WEB se compromete a no ceder, vender, ni compartir lo datos con terceros sin su aprobación expresa. Asimismo, NORVOZ TELECOM, S.L. cancelará o rectificará los datos cuando resulten inexactos, incompletos o hayan dejado de ser necesarios o pertinentes para su finalidad, de conformidad con lo previsto en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales. El usuario podrá revocar el consentimiento prestado y ejercer los derechos de acceso, rectificación, cancelación y oposición dirigiéndose a tal efecto al domicilio social de NORVOZ TELECOM, S.L., sito en C/ LODEIRO 4 ENTR. B – 27850 VIVEIRO - LUGO, identificándose debidamente e indicando de forma visible el concreto derecho que se ejerce.',
            parrafo32: 'EL PROPIETARIO DE LA WEB adopta los niveles de seguridad correspondientes requeridos por la citada Ley Orgánica 3/2018 y demás normativa aplicable. No obstante, no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que terceros pueden causar en los sistemas informáticos, documentos electrónicos o ficheros del usuario.',
            parrafo33: 'EL PROPIETARIO DE LA WEB podrá utilizar cookies durante la prestación de servicios del sitio web. Las cookies son ficheros físicos de información personal alojados en el propio terminal del usuario. El usuario tiene la posibilidad de configurar su programa navegador de manera que se impida la creación de archivos cookie o se advierta de la misma. Si opta a abandonar nuestro sitio web a través de enlaces a sitios web no pertenecientes a nuestra entidad, EL PROPIETARIO DE LA WEB no se hará responsable de las políticas de privacidad de dichos sitios web ni de las cookies que éstos puedan almacenar en el ordenador del usuario.',
            parrafo34: 'Nuestra política con respecto al correo electrónico se centra en remitir únicamente comunicaciones que usted haya solicitado recibir. Si prefiere no recibir estos mensajes por correo electrónico le ofreceremos a través de los mismos la posibilidad de ejercer su derecho de cancelación y renuncia a la recepción de estos mensajes, en conformidad con lo dispuesto en el Título III, artículo 22 de la Ley 34/2002 de Servicios para la Sociedad de la Información y de Comercio Electrónico.',
            titulo6: 'PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO',
            parrafo35: 'En el caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias que revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización de cualquier actividad en las páginas web incluidas o accesibles a través del sitio web, deberá enviar una notificación a EL PROPIETARIO DE LA WEB identificándose debidamente, especificando las supuestas infracciones y declarando expresamente y bajo su responsabilidad que la información proporcionada en la notificación es exacta.',
            parrafo36: 'Para toda cuestión litigiosa que incumba al sitio web de EL PROPIETARIO DE LA WEB, será de aplicación la legislación española, siendo competentes los Juzgados y Tribunales de LUGO (España).',
            titulo7: 'PUBLICACIONES',
            parrafo37: 'La información administrativa facilitada a través del sitio web no sustituye la publicidad legal de las leyes, normativas, planes, disposiciones generales y actos que tengan que ser publicados formalmente a los diarios oficiales de las administraciones públicas, que constituyen el único instrumento que da fe de su autenticidad y contenido. La información disponible en este sitio web debe entenderse como una guía sin propósito de validez legal'

        },
        politicaDeCookies: {
            parrafo1: 'Nuestro sitio web utiliza cookies para mejorar la navegación y obtener datos estadísticos sobre las visitas obtenidas.',
            parrafo2: 'Las cookies son pequeños archivos de texto que se instalan en el equipo desde el cual se visita nuestro sitio web y que nos proporcionan información de forma automática. Las cookies en ningún caso se pueden considerar como un virus informáticos. No dañan el ordenador ni ralentizan su funcionamiento en modo alguno. Usted puede eliminar nuestras Cookies en cualquier momento, o rechazarlas configurando su navegador web.',
            parrafo3: 'Las cookies se asocian únicamente con el navegador de un ordenador determinado (un usuario anónimo), y no proporcionan por sí el nombre y apellidos del usuario. Gracias a las cookies, resulta posible que Norvoz reconozca los navegadores de los usuarios registrados después de que éstos se hayan registrado por primera vez, sin que tengan que registrarse en cada visita para acceder a las áreas y servicios reservados exclusivamente a ellos. Nuestros "cookies" sirven para identificar una sesión de usuarios ("cookies de sesión") y / o un ordenador ("cookies temporales"), y no proporcionan por sí mismos ni el nombre del usuario ni ningún otro dato personal. Las cookies utilizadas no pueden leer los archivos cookie creados por otros proveedores. El usuario tiene la posibilidad de configurar su navegador para ser avisado en pantalla de la recepción de cookies y para impedir la instalación de cookies en su disco duro. Por favor, consulte las instrucciones y manuales de su navegador para ampliar esta información.',
            parrafo4: 'Norvoz utiliza dos tipos de cookies diferentes en nuestro sitio web:',
            parrafo5: {
                negrita: 'Cookies de terceras partes:',
                texto: 'Cookies analiticas web instaladas en su ordenador por el servicio Google Analytics. Son cookies estadísticas, con las cueles podemos conocer datos útiles para mejorar nuestra sitio web. Algunos datos recogidos son por ejemplo: el número de visitas recibidas, el origen de las visitas, las palabras clave utilizadas para encontranos o las horas de mayor afluencia de visitantes. Puede obtener más información sobre la política de privacidad de Google Analytics, visitando el siguiente enlace:'
            },
            parrafo6: 'Podrá configurar su navegador para no recibir estas cookie y serán rechazadas,. En caso contrario, entendemos que contamos con su consentimiento para su instalación.',
            parrafo7: 'Cookies propias, generadas por nuestro sitio web, para diferentes funciones:',
            parrafo8: {
                negrita: 'Cookies de autenticación: ',
                texto: 'Utilizadas para reconocer a los usuarios registrados que se han “logado” en nuestro sitio web. Con estas cookies, usted podrá acceder a las secciones de acceso restringido de la web. En caso de rechazar estas Cookies o ser borradas, las claves de acceso a nuestro sitio web, no funcionarán de forma correcta.'
            },
            parrafo9: {
                negrita: 'Cookies de personalización de interfaz: ',
                texto: 'Usadas para facilitar la navegación de nuestro sitio web. Permiten recordar algunos aspectos gráficos de nuestra web automáticamente. En caso de rechazar estas Cookies o ser borradas, la navegación en nuestro sitio web, no funcionarán de forma tan sencilla.'
            },
            parrafo10: {
                negrita: 'Cookies publicitarias: ',
                texto: 'Utilizadas para personalizar la publicidad que nuestro sitio web le proporciona en base a los contenidos de la web y la frecuencia a la que se muestren los anuncios.'
            },
            parrafo11: {
                negrita: 'Cookies de publicidad comportamental: ',
                texto: 'Estas cookies almacenan la información de nuestros usuarios y su comportamiento para después ofrecer en los espacios publicitarios disponibles información relevante para ellos. Para permitir, conocer, bloquear o eliminar las cookies instaladas en tu equipo puedes hacerlo mediante la configuración de las opciones del navegador instalado en su ordenador.'
            },
            parrafo12: 'Por ejemplo puedes encontrar información sobre como hacerlo como hacerlo en el caso que uses como navegador:',
            parrafo13: {
                texto: 'Firefox desde',
                enlace: 'http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we'
            },
            parrafo14: {
                texto: 'Chrome desde',
                enlace: 'http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647'
            },
            parrafo15: {
                texto: 'Explorer desde',
                enlace: 'http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9'

            },
            parrafo16: {
                texto: 'Safari desde',
                enlace: 'http://support.apple.com/kb/ph5042'
            },
            parrafo17: {
                texto: 'Si quieres más información sobre qué uso hacemos de las cookies, envíanos un e-mail a:',
                email: 'info@norvoz.es'

            }
        },
        informacionDatos: {
            parrafo1: {
                textoNegrita: 'Responsable:',
                texto: 'NORVOZ TELECOM, S.L.'
            },
            parrafo2: {
                textoNegrita: 'Domicilio social:',
                texto: 'C/ LODEIRO 4 ENTR. B – 27850 VIVEIRO – LUGO'
            },
            parrafo3: {
                textoNegrita: 'Tfno.:'
            },
            parrafo4: {
                textoNegrita: 'E-mail:'
            },
            parrafo5: {
                textoNegrita: 'Finalidad:',
                texto: 'Alta de servicios con Norvoz Telecom, S.L.'
            },
            parrafo6: {
                textoNegrita: 'Legitimación:',
                texto: 'Consentimiento del interesado.'
            },
            parrafo7: {
                textoNegrita: 'Destinatarios:',
                texto: 'Los datos de carácter personal que nos facilite mediante este formulario con la finalidad indicada anteriormente, podrán ser cedidos a terceros, únicamente cuando sea necesario para la gestión del alta del servicio, además de otras cesiones previstas en la ley. Puede ejercitar sus derechos según lo incluido a continuación.'
            },
            parrafo8: {
                textoNegrita: 'Derechos:',
                texto: 'Vd. tiene derecho a acceder, rectificar y suprimir los datos, así como a solicitar que se limite el tratamiento, oponerse al mismo, o solicitar la portabilidad de sus datos, como se explica en la información adicional.'
            },
            parrafo9: {
                textoNegrita: 'Duración:',
                texto: 'Los datos de carácter personal proporcionados se conservarán por un plazo razonable de tiempo o hasta el ejercicio del derecho de cancelación. Finalizado el plazo señalado los datos serán eliminados o bloqueados, salvo obligación legal en contrario.'
            },
            parrafo10: {
                textoNegrita: 'Información adicional:',
                textoParte1: 'Puede consultar la información adicional y detallada sobre Protección de Datos en el apartado 4 del',
                textoParte2: ' incluido en nuestra página web.'

            }
        },
        pedidoRealizado: {
            titulo: '¡GRACIAS!',
            parrafo1: 'Gracias por confiar en fibra.gal. Como queremos que ya empieces a disfrutar de la mayor velocidad...¡ya nos hemos puesto en marcha!',
            parrafo2: 'No queda nada para empezar a navegar a la mayor velocidad, los siguientes pasos son:',
            parrafo3: {
                parte1: 'Recibirás en tu móvil',
                parte2: ' los mensajes de texto con la documentación preparada y lista para firmar y remitirnos. Una vez recibamos dichos documentos, en menos de 7 días tendrás tu fibra instalada y...¡a navegar!'
            },
            parrafo4: '¡Muchas gracias de nuevo y... bienvenid@!'
        }

    }
}

export const i18n = new VueI18n({
    locale: 'gl',
    messages
})
