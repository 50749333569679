<template>
    <router-view />
</template>

<script>
export default {
    name: 'App',

    data: () => ({})
}
</script>

<style>
@import "./assets/css/styles.css";
@import '../node_modules/typeface-roboto/index.css';
</style>
