import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import axios from 'axios'
import Dropdown from 'vue-simple-search-dropdown'
import 'vue-search-select/dist/VueSearchSelect.css'
import UUID from 'vue-uuid'
import { i18n } from '@/plugins/i18n'
import './filtrosDecimales'
import VueGtm from '@gtm-support/vue2-gtm'

Vue.config.productionTip = false
Vue.use(Dropdown)
Vue.use(VueRouter)
Vue.use(UUID)

Vue.use(VueGtm, {
    id: 'GTM-5HCFXX4', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    enabled: false

})

Vue.prototype.$axios = axios
axios.defaults.timeout = 15000

new Vue({

    i18n,
    router,
    store,
    vuetify,
    axios,
    render: h => h(App)
}).$mount('#app')
