import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Fibra: '',
        LineasMovil: [],
        switchLineasMovil: '',
        switchFijo: '',
        codigoCoberturaFibra: '',
        carritoPedidosLineasMovil: '',
        jsonPedido: '',
        datosPortabilidadesValidados: '',
        datosPortabilidadesValidadosFijo: '',

        datosClienteValidados: '',
        total: '',
        fibraPreseleccionada: '',
        pedidoRealizado: false

    },
    mutations: {
        SET_FIBRA (state, Fibra) {
            state.Fibra = Fibra
        },
        SET_LINEASMOVIL (state, LineasMovil) {
            state.LineasMovil = LineasMovil
        },
        SET_SWITCHLINEASMOVIL (state, switchLineasMovil) {
            state.switchLineasMovil = switchLineasMovil
        },
        SET_SWITCHFIJO (state, switchFijo) {
            state.switchFijo = switchFijo
        },
        SET_CODIGOCOBERTURAFIBRA (state, codigoCoberturaFibra) {
            state.codigoCoberturaFibra = codigoCoberturaFibra
        },
        SET_CARRITOPEDIDOSLINEASMOVIL (state, carritoPedidosLineasMovil) {
            state.carritoPedidosLineasMovil = carritoPedidosLineasMovil
        },
        SET_JSONPEDIDO (state, jsonPedido) {
            state.jsonPedido = jsonPedido
        },
        SET_DATOSPORTABILIDADESVALIDADOS (state, datosPortabilidadesValidados) {
            state.datosPortabilidadesValidados = datosPortabilidadesValidados
        },
        SET_DATOSPORTABILIDADESVALIDADOSFIJO (state, datosPortabilidadesValidadosFijo) {
            state.datosPortabilidadesValidadosFijo = datosPortabilidadesValidadosFijo
        },

        SET_DATOSCLIENTEVALIDADOS (state, datosClienteValidados) {
            state.datosClienteValidados = datosClienteValidados
        },

        SET_TOTAL (state, total) {
            state.total = total
        },

        SET_FIBRAPRESELECCIONADA (state, fibraPreseleccionada) {
            state.fibraPreseleccionada = fibraPreseleccionada
        },
        SET_PEDIDOREALIZADO (state, pedidoRealizado) {
            state.pedidoRealizado = pedidoRealizado
        }
    },
    getters: {
        getFibra (state) {
            return state.Fibra
        },
        getLineasMovil (state) {
            return state.LineasMovil
        },
        getswitchLineasMovil (state) {
            return state.switchLineasMovil
        },
        getswitchFijo (state) {
            return state.switchFijo
        },
        getcodigoCoberturaFibra (state) {
            return state.codigoCoberturaFibra
        },
        getcarritoPedidosLineasMovil (state) {
            return state.carritoPedidosLineasMovil
        },
        getjsonPedido (state) {
            return state.jsonPedido
        },
        getdatosPortabilidadesValidados (state) {
            return state.datosPortabilidadesValidados
        },

        getdatosPortabilidadesValidadosFijo (state) {
            return state.datosPortabilidadesValidadosFijo
        },

        getdatosClienteValidados (state) {
            return state.datosClienteValidados
        },
        gettotal (state) {
            return state.total
        },
        getfibraPreseleccionada (state) {
            return state.fibraPreseleccionada
        },
        getpedidoRealizado (state) {
            return state.pedidoRealizado
        }
    },
    actions: {
        setFibra (context, Fibra) {
            context.commit('SET_FIBRA', Fibra)
        },
        setLineasMovil (context, LineasMovil) {
            context.commit('SET_LINEASMOVIL', LineasMovil)
        },
        setswitchLineasMovil (context, switchLineasMovil) {
            context.commit('SET_SWITCHLINEASMOVIL', switchLineasMovil)
        },
        setswitchFijo (context, switchFijo) {
            context.commit('SET_SWITCHFIJO', switchFijo)
        },
        setcodigoCoberturaFibra (context, codigoCoberturaFibra) {
            context.commit('SET_CODIGOCOBERTURAFIBRA', codigoCoberturaFibra)
        },
        setcarritoPedidosLineasMovil (context, carritoPedidosLineasMovil) {
            context.commit('SET_CARRITOPEDIDOSLINEASMOVIL', carritoPedidosLineasMovil)
        },
        setjsonPedido (context, jsonPedido) {
            context.commit('SET_JSONPEDIDO', jsonPedido)
        },
        setdatosPortabilidadesValidados (context, datosPortabilidadesValidados) {
            context.commit('SET_DATOSPORTABILIDADESVALIDADOS', datosPortabilidadesValidados)
        },

        setdatosPortabilidadesValidadosFijo (context, datosPortabilidadesValidadosFijo) {
            context.commit('SET_DATOSPORTABILIDADESVALIDADOSFIJO', datosPortabilidadesValidadosFijo)
        },

        setdatosClienteValidados (context, datosClienteValidados) {
            context.commit('SET_DATOSCLIENTEVALIDADOS', datosClienteValidados)
        },
        settotal (context, total) {
            context.commit('SET_TOTAL', total)
        },
        setfibraPreseleccionada (context, fibraPreseleccionada) {
            context.commit('SET_FIBRAPRESELECCIONADA', fibraPreseleccionada)
        },
        setpedidoRealizado (context, pedidoRealizado) {
            context.commit('SET_PEDIDOREALIZADO', pedidoRealizado)
        }
    }

})
