import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        component: () => import('./views/components/Index'),
        children: [
            // Dashboard
            {
                name: 'portada',
                path: '/',
                component: () => import('./views/components/portada')
            },
            {
                name: 'pedido',
                path: '/pedido',
                component: () => import('./views/configuradorPedido')
            },
            {
                name: 'aviso-legal',
                path: '/aviso-legal',
                component: () => import('./views/components/avisoLegal')
            },
            {
                name: 'informacion-basica-datos',
                path: '/informacion-basica-datos',
                component: () => import('./views/components/informacionDatos')
            },
            {
                name: 'politica-cookies',
                path: '/politica-cookies',
                component: () => import('./views/components/politicaDeCookies')
            }

        ]

    }

]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

export default router
